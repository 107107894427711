@function hex-color($color) {
  @if type-of($color) == 'color' {
    $color: str-slice(ie-hex-str($color), 4);
  }
  @return '%23' + unquote($color)
}

body {
  font-family: $font-sans-serif, sans-serif;
  background: darken($ui-base-color, 7%);
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: $primary-text-color;
  text-rendering: optimizelegibility;
  font-feature-settings: "kern";
  text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  &.system-font {
    // system-ui => standard property (Chrome/Android WebView 56+, Opera 43+, Safari 11+)
    // -apple-system => Safari <11 specific
    // BlinkMacSystemFont => Chrome <56 on macOS specific
    // Segoe UI => Windows 7/8/10
    // Oxygen => KDE
    // Ubuntu => Unity/Ubuntu
    // Cantarell => GNOME
    // Fira Sans => Firefox OS
    // Droid Sans => Older Androids (<4.0)
    // Helvetica Neue => Older macOS <10.11
    // $font-sans-serif => web-font (Roboto) fallback and newer Androids (>=4.0)
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", $font-sans-serif, sans-serif;
  }

  &.app-body {
    padding: 0;

    &.layout-single-column {
      height: auto;
      min-height: 100vh;
      overflow-y: scroll;
    }

    &.layout-multiple-columns {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &.with-modals--active {
      overflow-y: hidden;
    }
  }

  &.lighter {
    background: $ui-base-color;
  }

  &.with-modals {
    overflow-x: hidden;
    overflow-y: scroll;

    &--active {
      overflow-y: hidden;
    }
  }

  &.player {
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > div {
      height: 100%;
    }

    .video-player video {
      width: 100%;
      height: 100%;
      max-height: 100vh;
    }

    .media-gallery {
      margin-top: 0;
      height: 100% !important;
      border-radius: 0;
    }

    .media-gallery__item {
      border-radius: 0;
    }
  }

  &.embed {
    background: lighten($ui-base-color, 4%);
    margin: 0;
    padding-bottom: 0;

    .container {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  &.admin {
    background: darken($ui-base-color, 4%);
    padding: 0;
  }

  &.error {
    position: absolute;
    text-align: center;
    color: $darker-text-color;
    background: $ui-base-color;
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog {
      vertical-align: middle;
      margin: 20px;

      img {
        display: block;
        max-width: 470px;
        width: 100%;
        height: auto;
        margin-top: -120px;
      }

      h1 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
}

button {
  font-family: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.app-holder {
  &,
  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    outline: 0 !important;
  }
}

.layout-single-column .app-holder {
  &,
  & > div {
    min-height: 100vh;
  }
}

.layout-multiple-columns .app-holder {
  &,
  & > div {
    height: 100%;
  }
}

.logo-resources {
  // Not using display: none because of https://bugs.chromium.org/p/chromium/issues/detail?id=258029
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
}

// NoScript adds a __ns__pop2top class to the full ancestry of blocked elements,
// to set the z-index to a high value, which messes with modals and dropdowns.
// Blocked elements can in theory only be media and frames/embeds, so they
// should only appear in statuses, under divs and articles.
body,
div,
article {
  .__ns__pop2top {
    z-index: unset !important;
  }
}
